export const MAIN_DATA = {
  languages: [],
  phoneNumber: "+39 031 497 1833",
  lunchTime: "12:00 - 15:00",
  dinnerTime: "19:00 - 23:00",
  logoLink: "assets/img/logo_main.png",
  mainMenu: [
    { name: "La storia", hashtag: "#about" },
    { name: "Menu", hashtag: "/menu" },

    { name: "Specialita'", hashtag: "#specials" },
    { name: "Eventi", hashtag: "#events" },
    //{ name: "Staff", hashtag: "#staff" },
    { name: "Gallery", hashtag: "#gallery" },
    { name: "Contatto", hashtag: "#contact" },
    { name: "Franchising", hashtag: "#contact" },
  ],
  bookingButton: "Prenota ora",
  caroulselContent: [
    {
      activeMain: "carousel-item active",
      backgroundImage: "assets/img/CM5A3459.jpeg",
      span: "Ca' Shilia",
      title: "Sushi Mediterraneo",
      mainContent:
        "Benvenuti nel nostro ristorante di sushi italiano, dove la tradizione culinaria giapponese incontra i sapori autentici del Sud Italia. Qui, gli ingredienti freschi e di qualità della Calabria e della Sicilia si fondono con l’arte del sushi per creare un’esperienza gastronomica unica. Preparati a scoprire un viaggio culinario che unisce due culture in un connubio di sapori indimenticabili.",
      buttonsContent: [{ buttonName: "Vai al Menu", buttonLink: "/menu" }],
    },
    {
      activeMain: "carousel-item",
      backgroundImage: "assets/img/CM5A3304.jpeg",
      span: "Ciao,",
      title: "sei pronto?",
      mainContent:
        "Immergiti in un mondo dove l’Est incontra il Sud dell’Italia. Dove l’eleganza del sushi si fonde con i sapori robusti e autentici della Calabria e della Sicilia. Ogni boccone è un viaggio che attraversa mari e montagne, unendo la delicatezza del sushi con l’intensità dei sapori italiani. Vieni a scoprire un’avventura culinaria che ti lascerà senza parole.",
      buttonsContent: [
        { buttonName: "Prenota ora!", buttonLink: "#book-a-table" },
      ],
    },
    {
      activeMain: "carousel-item",
      backgroundImage: "assets/img/CM5A3274.jpeg",
      span: "Olio",
      title: "Zavaglia",
      mainContent:
        "L’Olio Zavaglia è noto per la sua qualità e per la massima espressione della tradizione artigianale calabrese del buon olio. L’olio vergine d’oliva è un componente essenziale della tradizione alimentare di molte aree del Mediterraneo. È un ingrediente genuino, naturale, capace di arricchire di sapore e di colore moltissimi piatti, dal più semplice al più sofisticato.",
      buttonsContent: [
        { buttonName: "Olio Zavaglia", buttonLink: "https://oliozavaglia.it/" },
      ],
    },
  ],
  mainSection_01: {
    contentH3:
      "Ca’ shilia nasce da un sogno, quello di Letizia e Mirko, di creare lontano dalla loro CASA, il MARE, un luogo che sappia di casa, con i suoi odori, profumi, sapori e valori.",
    contentP:
      "Il viaggio parte da lontano, dall’estremo sud dell’ Italia, per arrivare al nord, dove oggi vivono, passando tramite esperienze vissute dal Giappone, per tornare sempre col cuore lì dove affondano le loro RADICI e da dove nascono le ispirazioni che caratterizzano cà shilia.",

    contentChecksIndicators: [
      "Questo viaggio abbraccia culture e tradizioni apparentemente lontane, due mondi, l’oriente e l’occidente che la natura ha diviso, ma che cà shiliavuole unire valorizzando ciò che di speciale hanno in comune: l’accoglienza, fondamentale per la gente del sud quanto per il popolo nipponico, il valore che viene dato alle persone e al loro benessere, il rispetto per i doni offerti dal mare e dalla terra, che bagnata dal mare produce elementi pregiati a cui viene dato un alto valore, anche simbolico.",
      "Cà shilia è una finestra su questi mondi, si affaccia sul mare e con le sue onde vi trascinerà verso luoghi intrisi di storia e sentimenti, di profumi e antiche tradizioni che vi faranno sentire forte il calore del SOLE e il sapore dell’Italia meridionale, accuratamente fuso con quello tipico del Giappone, in un SUSHI MEDITERRANEO che vi conquisterà.",
    ],
    lastContent:
      '"Cà shilia per noi è  CAsa, è CAlabria, è SicILIAE’ vivere insieme un momento di condivisione di qualcosa di speciale, il suSHI, con tutti i valori che racchiude. Cà shilia ha il nostro animo, aperto alle novità ma profondamente legato alle nostre radici!” (Letizia & Mirko)',
  },
  whyUsSection: {
    tittle: "Perché Noi",
    subTittle: "Perche' scegliere Ca' Shilia?",
    boxContent: [
      {
        tittle: "01",
        subTittle: "Un Viaggio di Sapori:",
        content:
          "Immergiti in un’esperienza culinaria unica nel suo genere. Una fusione di sapori calabresi e siciliani, creando un’esperienza di sushi come nessun’altra. Ogni boccone è un viaggio di sapori, unendo il meglio del Mediterraneo con la raffinatezza del sushi.",
      },
      {
        tittle: "02",
        subTittle: "Sushi con un Twist:",
        content:
          "Sei stanco del solito sushi? Vieni a provare il nostro sushi mediterraneo! In Ca' Shilia utilizziamo solo i migliori ingredienti italiani per dare un twist al tradizionale sushi. Il risultato? Un’esperienza culinaria che ti farà innamorare del sushi tutto daccapo.",
      },
      {
        tittle: "03",
        subTittle: "Sushi Mediterraneo:",
        content:
          "Un’esperienza culinaria unica tutta da scoprire, combinando la freschezza degli ingredienti calabresi e siciliani con l’arte del sushi. Scopri come l’Oriente incontra il Sud Italia nel tuo piatto, creando un’esplosione di sapori che ti farà tornare per di più.",
      },
    ],
  },
  menuSection: {
    tittle: "Menu",
    subTittle: "Check our Tasty menu",
    mainFilterAll: "All",
    categoriesNames: [
      { filterName: ".filter-starters", categoryName: "Starters" },
      { filterName: ".filter-salads", categoryName: "Salads" },
      { filterName: ".filter-specialty", categoryName: "Specialty" },
      { filterName: ".filter-nigiri", categoryName: "Nigiri" },
    ],
    menu_items: [
      {
        name: "Lobster Bisque",
        price: "$5.95",
        image: "assets/img/menu/mozzarella.jpg",
        href: "^8^",
        alt: "A bowl of lobster bisque soup with bread and parsley",
        ingredients: "Lorem, deren, trataro, filede, nerada",
        className: "col-lg-6 menu-item filter-starters",
      },
      {
        name: "Bread Barrel",
        price: "$6.95",
        image: "assets/img/menu/mozzarella.jpg",
        href: "^9^",
        alt: "A wooden barrel filled with various types of bread",
        ingredients: "Lorem, deren, trataro, filede, nerada",
        className: "col-lg-6 menu-item filter-sushi",
      },
      {
        name: "Crab Cake",
        price: "$7.95",
        image: "assets/img/menu/mozzarella.jpg",
        href: "^10^",
        alt: "A plate of crab cake with lettuce, tomato, and sauce",
        ingredients:
          "A delicate crab cake served on a toasted roll with lettuce and tartar sauce",
        className: "col-lg-6 menu-item filter-starters",
      },
      {
        name: "Caesar Selections",
        price: "$8.95",
        image: "assets/img/menu/mozzarella.jpg",
        href: "^11^",
        alt: "A bowl of caesar salad with croutons, cheese, and dressing",
        ingredients: "Lorem, deren, trataro, filede, nerada",
        className: "col-lg-6 menu-item filter-salads",
      },
      {
        name: "Tuscan Grilled",
        price: "$9.95",
        image: "assets/img/menu/mozzarella.jpg",
        href: "^12^",
        alt: "A sandwich of tuscan grilled chicken with cheese and vegetables",
        ingredients:
          "Grilled chicken with provolone, artichoke hearts, and roasted red pesto",
        className: "col-lg-6 menu-item filter-specialty",
      },
      {
        name: "Mozzarella Stick",
        price: "$4.95",
        image: "assets/img/menu/mozzarella.jpg",
        href: "^13^",
        alt: "A basket of mozzarella sticks with marinara sauce",
        ingredients: "Lorem, deren, trataro, filede, nerada",
        className: "col-lg-6 menu-item filter-starters",
      },
      {
        name: "Greek Salad",
        price: "$9.95",
        image: "assets/img/menu/mozzarella.jpg",
        href: "^14^",
        alt: "A plate of greek salad with spinach, romaine, tomatoes, and olives",
        ingredients: "Fresh spinach, crisp romaine, tomatoes, and Greek olives",
        className: "col-lg-6 menu-item filter-salads",
      },
      {
        name: "Greek Salad",
        price: "$9.95",
        image: "assets/img/menu/mozzarella.jpg",
        href: "^14^",
        alt: "A plate of greek salad with spinach, romaine, tomatoes, and olives",
        ingredients: "Fresh spinach, crisp romaine, tomatoes, and Greek olives",
        className: "col-lg-6 menu-item filter-nigiri",
      },
    ],
  },
  specials: {
    tittle: "Specials",
    subTittle: 'I nostri classici "specials"',
    tabItem: {
      itemTittles: [
        {
          className: "nav-link active show",
          href: "#tab-1",
          text: "Tartare di Pesce",
        },
        {
          className: "nav-link",
          href: "#tab-2",
          text: "Uramaki Italiano",
        },
        {
          className: "nav-link",
          href: "#tab-3",
          text: "Cannoli di Mare",
        },
        {
          className: "nav-link",
          href: "#tab-4",
          text: "Sashimi",
        },
      ],
      itemContents: [
        {
          className: "tab-pane active show",
          id: "tab-1",
          inestedClassName: "col-lg-8 details order-2 order-lg-1",
          h3El: "Un Tuffo nei Sapori di Sicilia e Calabria",
          firstContent:
            "La nostra tartare di pesce è un’esplosione di sapori mediterranei. Realizzata con il pesce più fresco, questa pietanza unisce la delicatezza del pesce crudo con la ricchezza degli ingredienti siciliani e calabresi.",
          mainContent:
            "Combinano in modi diversi in base alla scelta, pesce fresco, dadolata d’arance, scorzetta di limone, cipolla rossa di Tropea, finocchio, olive nere, olio evo, sale, pepe nero, menta e pinoli. Un piatto che racchiude il sole e il mare del Sud Italia in un’esplosione di sapori. Buon appetito!",
          lastInestedClassName: "col-lg-4 text-center order-1 order-lg-2",
          imageSrc: "assets/img/CM5A3414_.png",

          imageAlt: "",
        },
        {
          className: "tab-pane",
          id: "tab-2",
          inestedClassName: "col-lg-8 details order-2 order-lg-1",
          h3El: "Sushi Roll alla Mediterranea",
          firstContent:
            "Immagina un sushi roll che cattura l’essenza del Sud Italia. Il riso, tenero e leggermente dolce, avvolge un cuore di ingredienti tipici siciliani e calabresi. Potrebbe esserci un tocco di agrumi per un pizzico di acidità, olive nere per un sapore robusto e terroso, e forse un filo di olio d’oliva per legare il tutto. Un pizzico di peperoncino potrebbe aggiungere un tocco di calore, mentre un pizzico di origano secco potrebbe evocare i profumi della campagna. ",
          mainContent:
            "Questo sushi roll non è solo un piatto, ma un viaggio di sapori che unisce Oriente e Sud Italia.",
          lastInestedClassName: "col-lg-4 text-center order-1 order-lg-2",
          imageSrc: "assets/img/CM5A3282_.png",
          imageAlt: "",
        },
        {
          className: "tab-pane",
          id: "tab-3",
          inestedClassName: "col-lg-8 details order-2 order-lg-1",
          h3El: "“Un’Innovazione di Sapori tra il Pesce Crudo e il Sud Italia”",
          firstContent:
            "Immagina un incontro tra la tradizione siciliana e i sapori del mare, che si fondono in un’unica sinfonia di sapori. Questo è il nostro “Cannoli di Mare”, un piatto che rappresenta un viaggio culinario attraverso i sapori vibranti del Sud Italia, uniti all’eleganza del pesce crudo.",
          mainContent:
            "Il protagonista di questo viaggio è il pesce fresco del Mediterraneo, tagliato con maestria in sottili fette, che cattura l’essenza del mare in ogni boccone. Il pesce, pescato nelle acque cristalline che bagnano le coste del Sud Italia, offre un sapore fresco e delicato che fa da tela bianca ai sapori più audaci del Sud.",
          lastInestedClassName: "col-lg-4 text-center order-1 order-lg-2",
          imageSrc: "assets/img/CM5A3773_.png",
          imageAlt: "",
        },
        {
          className: "tab-pane",
          id: "tab-4",
          inestedClassName: "col-lg-8 details order-2 order-lg-1",
          h3El: "Sinfonia di Sapori: L’Incontro tra Sashimi e il Sud Italia",
          firstContent:
            "Immagina un incontro tra due mondi, l’Oriente e il Mediterraneo, che si fondono in un’unica sinfonia di sapori. Questo è il nostro “Sashimi del Sud”, un piatto che rappresenta un viaggio culinario attraverso i sapori vibranti della Sicilia e della Calabria, uniti all’eleganza del sashimi giapponese.",
          mainContent:
            "Gli ingredienti del Sud Italia, raccolti nei campi soleggiati e nei frutteti rigogliosi, donano al sashimi un tocco di dolcezza e complessità. I sapori dolci, agrumati, piccanti e aromatici si fondono in un connubio perfetto, creando un’esperienza culinaria unica.",
          lastInestedClassName: "col-lg-4 text-center order-1 order-lg-2",
          imageSrc: "assets/img/sashimi.png",

          imageAlt: "",
        },
      ],
    },
  },
  events: {
    background: "assets/img/",
    tittle: "Eventi",
    subTittle: "Celebra i Tuoi Momenti Speciali con un Tocco Mediterraneo",
    image: "assets/img/CM5A3114.jpg",
    mainTittle: "Celebra da noi!",
    price: "",
    firstContent:
      "Stai cercando il luogo perfetto per il tuo prossimo evento? Il nostro ristorante di sushi mediterraneo offre un’ambientazione unica e un menu innovativo che combina la raffinatezza del sushi con i sapori vibranti del Sud Italia.",
    liContent: [
      "Che si tratti di un compleanno, un anniversario, una festa aziendale o altro ancora, il nostro ristorante è il luogo ideale per rendere il tuo evento indimenticabile. Offriamo un’ampia selezione di piatti preparati con ingredienti freschi e di qualità provenienti dalla Calabria e dalla Sicilia, garantendo un’esperienza culinaria che i tuoi ospiti non dimenticheranno.",
    ],
    lastContent:
      "Vieni a scoprire come possiamo trasformare il tuo evento in un’esperienza culinaria unica. Contattaci oggi per saperne di più sulle nostre opzioni per eventi. Ti aspettiamo per celebrare insieme i tuoi momenti speciali!",
  },
  reservations: {},
  gallery: {
    tittle: "Gallery",
    subtittle: "Un po' di Ca' Shilia...",
    pictures: [
      {
        name: "01",
        src: "assets/img/gallery/01.jpeg",
      },
      {
        name: "02",
        src: "assets/img/gallery/02.jpeg",
      },
      {
        name: "03",
        src: "assets/img/gallery/03.jpeg",
      },
      {
        name: "04",
        src: "assets/img/gallery/04.png",
      },
      {
        name: "05",
        src: "assets/img/gallery/07.jpeg",
      },
      {
        name: "06",
        src: "assets/img/gallery/08.jpeg",
      },
      {
        name: "07",
        src: "assets/img/gallery/09.jpeg",
      },
      {
        name: "08",
        src: "assets/img/gallery/10.jpeg",
      },
    ],
  },
  staff: {
    tittle: "Staff",
    subTittle: "Our Proffesional staff",
    people: [
      {
        name: "Tonni",
        ruolo: "Operations Manager",
        src: "assets/img/chefs/chefs-1.jpg",
        twitter: "",
        facebook: "",
        instagram: "",
        linkedin: "",
      },
      {
        name: "Lucia",
        ruolo: "SEO & Manager",
        src: "assets/img/chefs/chefs-2.jpg",
        twitter: "",
        facebook: "",
        instagram: "",
        linkedin: "",
      },
      {
        name: "Jamal",
        ruolo: "Master Cheff",
        src: "assets/img/chefs/chefs-3.jpg",
        twitter: "",
        facebook: "",
        instagram: "",
        linkedin: "",
      },
    ],
  },
  contact: {
    tittle: "Contact",
    subTittle: "Contattaci",
    address: {
      tittle: "Location",
      address: "Via Risorgimento, 2, 22063 Cantù CO",
      href: "https://www.google.com/maps/place/Via+Risorgimento,+2,+22063+Cant%C3%B9+CO/@45.7367807,9.1274303,17z/data=!3m1!4b1!4m6!3m5!1s0x478699030a33cc91:0x9f512e8f942ee502!8m2!3d45.7367807!4d9.1274303!16s%2Fg%2F11cs6_95bp?entry=ttu",
    },
    openHours: {
      tittle: "Orari di apertura:",
      openHour: [
        { days: "Martedì a Domenica:", hours: "12:00 - 15:00 e 19:00 - 23:00" },
        {
          days: "Lunedì: CHIUSO",
          hours: "",
        },
        {
          days: "* Gli orari di apertura potrebbero variare. Contattaci per ulteriori informazioni/ conferme.",
          hours: "",
        },
      ],
    },
    mail: { tittle: "E-mail: ", mail: "info@cashilia.it" },
    phone: { tittle: "Telefono: ", phoneNumber: "+39 031 497 1833" },
  },
  footerContent: {
    tittleFirstSection: "Link utili",
    footerMenu_01: [
      { name: "Home", hashtag: "#" },
      { name: "Menu'", hashtag: "/menu" },
      //{ name: "La storia", hashtag: "#about" },
      { name: "Contatto", hashtag: "#contact" },
      { name: "Terms of service", hashtag: "#" },
      { name: "Privacy policy", hashtag: "#" },
    ],
    tittleSecondSection: "Partner Ufficiale",
    footerMenu_02: [
      {
        name: "Olio Zavaglia",
        hashtag: "https://oliozavaglia.it/",
        icon: "https://oliozavaglia.it/cdn/shop/files/Logo_2018_png_457x257.png?v=1613747858",
      },
    ],
    newsletter: {
      tittle: "La nostra Newsletter",
      content:
        "Vuoi rimanere sempre aggiornato sulle nostre novità? Allora iscriviti alla nostra newsletter!",
      buttonName: "Subscribe",
    },
    footerSocial: [
      //{ name: "Twitter", icon: "bx bxl-twitter", src: "#" },
      {
        name: "Facebook",
        icon: "bx bxl-facebook",
        src: "https://www.facebook.com/people/Ca-Shilia/61554750603183/",
      },
      {
        name: "Instagram",
        icon: "bx bxl-instagram",
        src: "https://www.instagram.com/ca.shilia/?igsh=YTQwZjQ0NmI0OA%3D%3D",
      },
      //{ name: "Skipe", icon: "bx bxl-skype", src: "#" },
      //{ name: "Linkedin", icon: "bx bxl-linkedin", src: "#" },
    ],
  },
};

/*
export const MAIN_DATA = {
  languages: ["It", "En", "Pt"],
  phoneNumber: "+39 000 0000 000",
  lunchTime: "Chiuso i Lunedì",
  dinnerTime: "",
  logoLink: "assets/img/logo_main.png",
  mainMenu: [
    { name: "About", hashtag: "#about" },
    { name: "Menu", hashtag: "#menu" },
    { name: "Specials", hashtag: "#specials" },
    { name: "Events", hashtag: "#events" },
    { name: "Staff", hashtag: "#staff" },
    { name: "Gallery", hashtag: "#gallery" },
    { name: "Contact", hashtag: "#contact" },
    { name: "Franchising", hashtag: "#contact" },
  ],
  bookingButton: "Prenota ora",
  caroulselContent: [
    {
      activeMain: "carousel-item active",
      backgroundImage: "assets/img/main.jpg",
      span: "Ca' SHILIA",
      title: "Ristorante",
      mainContent:
        "Ut velit est quam dolor ad a aliquid qui aliquid. Sequi eaut et est quaerat sequi nihil ut aliquam. Occaecati aliasdolorem mollitia ut. Similique ea voluptatem. Esse doloremque accusamus repellendus deleniti vel. Minus et tempore modi architecto.",
      buttonsContent: [
        { buttonName: "Il nostro menu", buttonLink: "#menu" },
        { buttonName: "prenota un tavolo", buttonLink: "#book-a-table" },
      ],
    },
    {
      activeMain: "carousel-item",
      backgroundImage:
        "https://www.triptosicily.com/wp-content/uploads/2016/05/Caltagirone-Ceramica-1.jpg",
      span: "Ciao",
      title: "Sequi ea ut et est quaerat",
      mainContent:
        "Ut velit est quam dolor ad a aliquid qui aliquid. Sequi eaut et est quaerat sequi nihil ut aliquam. Occaecati aliasdolorem mollitia ut. Similique ea voluptatem. Esse doloremque accusamus repellendus deleniti vel. Minus et tempore modi architecto.",
      buttonsContent: [{ buttonName: "Chiama ora!", buttonLink: "#menu" }],
    },
    {
      activeMain: "carousel-item",
      backgroundImage:
        "https://www.triptosicily.com/wp-content/uploads/2016/05/Caltagirone-Ceramica-1.jpg",
      span: "",
      title: "Ristorante",
      mainContent:
        "Ut velit est quam dolor ad a aliquid qui aliquid. Sequi eaut et est quaerat sequi nihil ut aliquam. Occaecati aliasdolorem mollitia ut. Similique ea voluptatem. Esse doloremque accusamus repellendus deleniti vel. Minus et tempore modi architecto.",
      buttonsContent: [{ buttonName: "Olio Italiano", buttonLink: "#menu" }],
    },
  ],
  mainSection_01: {
    contentH3:
      "Voluptatem dignissimos provident quasi corporis voluptates sit assumenda.",
    contentP:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, seddo eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    contentChecksIndicators: [
      "Ullamco laboris nisi ut aliquip ex ea commodo consequat.",
      "Duis aute irure dolor in reprehenderit in voluptate velit.",
      "Ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate trideta storacalaperda mastiro dolore eu fugiat nulla pariatur.",
    ],
    lastContent:
      "Ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officiadeserunt mollit anim id est laborum",
  },
  whyUsSection: {
    tittle: "Why Us",
    subTittle: "Why Choose Our Restaurant",
    boxContent: [
      {
        tittle: "01",
        subTittle: "Lorem Ipsum",
        content:
          "Ulamco laboris nisi ut aliquip ex ea commodo consequat. Etconsectetur ducimus vero placeat",
      },
      {
        tittle: "02",
        subTittle: "Repellat Nihil",
        content:
          "Dolorem est fugiat occaecati voluptate velit esse. Dicta veritatis dolor quod et vel dire leno para dest",
      },
      {
        tittle: "03",
        subTittle: "Ad ad velit qui",
        content:
          "Molestiae officiis omnis illo asperiores. Aut doloribus vitae sunt debitis quo vel nam quis",
      },
    ],
  },
  menuSection: {
    tittle: "Menu",
    subTittle: "Check our Tasty menu",
    mainFilterAll: "All",
    categoriesNames: [
      { filterName: ".filter-starters", categoryName: "Starters" },
      { filterName: ".filter-salads", categoryName: "Salads" },
      { filterName: ".filter-specialty", categoryName: "Specialty" },
      { filterName: ".filter-nigiri", categoryName: "Nigiri" },
    ],
    menu_items: [
      {
        name: "Lobster Bisque",
        price: "$5.95",
        image: "assets/img/menu/mozzarella.jpg",
        href: "^8^",
        alt: "A bowl of lobster bisque soup with bread and parsley",
        ingredients: "Lorem, deren, trataro, filede, nerada",
        className: "col-lg-6 menu-item filter-starters",
      },
      {
        name: "Bread Barrel",
        price: "$6.95",
        image: "assets/img/menu/mozzarella.jpg",
        href: "^9^",
        alt: "A wooden barrel filled with various types of bread",
        ingredients: "Lorem, deren, trataro, filede, nerada",
        className: "col-lg-6 menu-item filter-sushi",
      },
      {
        name: "Crab Cake",
        price: "$7.95",
        image: "assets/img/menu/mozzarella.jpg",
        href: "^10^",
        alt: "A plate of crab cake with lettuce, tomato, and sauce",
        ingredients:
          "A delicate crab cake served on a toasted roll with lettuce and tartar sauce",
        className: "col-lg-6 menu-item filter-starters",
      },
      {
        name: "Caesar Selections",
        price: "$8.95",
        image: "assets/img/menu/mozzarella.jpg",
        href: "^11^",
        alt: "A bowl of caesar salad with croutons, cheese, and dressing",
        ingredients: "Lorem, deren, trataro, filede, nerada",
        className: "col-lg-6 menu-item filter-salads",
      },
      {
        name: "Tuscan Grilled",
        price: "$9.95",
        image: "assets/img/menu/mozzarella.jpg",
        href: "^12^",
        alt: "A sandwich of tuscan grilled chicken with cheese and vegetables",
        ingredients:
          "Grilled chicken with provolone, artichoke hearts, and roasted red pesto",
        className: "col-lg-6 menu-item filter-specialty",
      },
      {
        name: "Mozzarella Stick",
        price: "$4.95",
        image: "assets/img/menu/mozzarella.jpg",
        href: "^13^",
        alt: "A basket of mozzarella sticks with marinara sauce",
        ingredients: "Lorem, deren, trataro, filede, nerada",
        className: "col-lg-6 menu-item filter-starters",
      },
      {
        name: "Greek Salad",
        price: "$9.95",
        image: "assets/img/menu/mozzarella.jpg",
        href: "^14^",
        alt: "A plate of greek salad with spinach, romaine, tomatoes, and olives",
        ingredients: "Fresh spinach, crisp romaine, tomatoes, and Greek olives",
        className: "col-lg-6 menu-item filter-salads",
      },
      {
        name: "Greek Salad",
        price: "$9.95",
        image: "assets/img/menu/mozzarella.jpg",
        href: "^14^",
        alt: "A plate of greek salad with spinach, romaine, tomatoes, and olives",
        ingredients: "Fresh spinach, crisp romaine, tomatoes, and Greek olives",
        className: "col-lg-6 menu-item filter-nigiri",
      },
    ],
  },
  specials: {
    tittle: "Specials",
    subTittle: "Check our specials",
    tabItem: {
      itemTittles: [
        {
          className: "nav-link active show",
          href: "#tab-1",
          text: "Modi sit est",
        },
        {
          className: "nav-link",
          href: "#tab-2",
          text: "Unde praesentium sed",
        },
        {
          className: "nav-link",
          href: "#tab-4",
          text: "Nostrum qui quasi",
        },
        {
          className: "nav-link",
          href: "#tab-5",
          text: "Iusto ut expedita aut",
        },
      ],
      itemContents: [
        {
          className: "tab-pane active show",
          id: "tab-1",
          inestedClassName: "col-lg-8 details order-2 order-lg-1",
          h3El: "Architecto ut aperiam autem id",
          firstContent:
            "Qui laudantium consequatur laborum sit qui ad sapiente dila parde sonata raqer a videna mareta paulona marka",
          mainContent:
            "Et nobis maiores eius. Voluptatibus ut enim blanditiis atque harum sint. Laborum eos ipsum ipsa odit magni.Incidunt hic ut molestiae aut qui. Est repellat minima eveniet eius et quis magni nihil. Consequatur dolorem quaerat quos qui similique accusamus nostrum rem vero",
          lastInestedClassName: "col-lg-4 text-center order-1 order-lg-2",
          imageSrc: "assets/img/specials-1.png",
          imageAlt: "",
        },
        {
          className: "tab-pane",
          id: "tab-2",
          inestedClassName: "col-lg-8 details order-2 order-lg-1",
          h3El: "Architecto ut aperiam autem id",
          firstContent:
            "Qui laudantium consequatur laborum sit qui ad sapiente dila parde sonata raqer a videna mareta paulona marka",
          mainContent:
            "Et nobis maiores eius. Voluptatibus ut enim blanditiis atque harum sint. Laborum eos ipsum ipsa odit magni.Incidunt hic ut molestiae aut qui. Est repellat minima eveniet eius et quis magni nihil. Consequatur dolorem quaerat quos qui similique accusamus nostrum rem vero",
          lastInestedClassName: "col-lg-4 text-center order-1 order-lg-2",
          imageSrc: "assets/img/specials-2.png",
          imageAlt: "",
        },
        {
          className: "tab-pane",
          id: "tan-3",
          inestedClassName: "col-lg-8 details order-2 order-lg-1",
          h3El: "Architecto ut aperiam autem id",
          firstContent:
            "Qui laudantium consequatur laborum sit qui ad sapiente dila parde sonata raqer a videna mareta paulona marka",
          mainContent:
            "Et nobis maiores eius. Voluptatibus ut enim blanditiis atque harum sint. Laborum eos ipsum ipsa odit magni.Incidunt hic ut molestiae aut qui. Est repellat minima eveniet eius et quis magni nihil. Consequatur dolorem quaerat quos qui similique accusamus nostrum rem vero",
          lastInestedClassName: "col-lg-4 text-center order-1 order-lg-2",
          imageSrc: "assets/img/specials-3.png",
          imageAlt: "",
        },
        {
          className: "tab-pane",
          id: "tab-4",
          inestedClassName: "col-lg-8 details order-2 order-lg-1",
          h3El: "Architecto ut aperiam autem id",
          firstContent:
            "Qui laudantium consequatur laborum sit qui ad sapiente dila parde sonata raqer a videna mareta paulona marka",
          mainContent:
            "Et nobis maiores eius. Voluptatibus ut enim blanditiis atque harum sint. Laborum eos ipsum ipsa odit magni.Incidunt hic ut molestiae aut qui. Est repellat minima eveniet eius et quis magni nihil. Consequatur dolorem quaerat quos qui similique accusamus nostrum rem vero",
          lastInestedClassName: "col-lg-4 text-center order-1 order-lg-2",
          imageSrc: "assets/img/specials-4.png",
          imageAlt: "",
        },
        {
          className: "tab-pane",
          id: "tab-5",
          inestedClassName: "col-lg-8 details order-2 order-lg-1",
          h3El: "Architecto ut aperiam autem id",
          firstContent:
            "Qui laudantium consequatur laborum sit qui ad sapiente dila parde sonata raqer a videna mareta paulona marka",
          mainContent:
            "Et nobis maiores eius. Voluptatibus ut enim blanditiis atque harum sint. Laborum eos ipsum ipsa odit magni.Incidunt hic ut molestiae aut qui. Est repellat minima eveniet eius et quis magni nihil. Consequatur dolorem quaerat quos qui similique accusamus nostrum rem vero",
          lastInestedClassName: "col-lg-4 text-center order-1 order-lg-2",
          imageSrc: "assets/img/specials-5.png",
          imageAlt: "",
        },
      ],
    },
  },
  events: {
    background: "assets/img/events-bg.jpg",
    tittle: "Events",
    subTittle: "Organize Your Events in our Restaurant",
    image: "assets/img/event-private.jpg",
    mainTittle: "Private Parties",
    price: "$290",
    firstContent:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit,sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    liContent: [
      "Ullamco laboris nisi ut aliquip ex ea commodo consequat.",
      "Duis aute irure dolor in reprehenderit in voluptate velit.",
      " Ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    ],
    lastContent:
      "Ullamco laboris nisi ut aliquip ex ea commodo consequat. uis aute irure dolor in reprehenderit in voluptatevelit esse cillum dolore eu fugiat nulla pariatur",
  },
  reservations: {},
  gallery: {
    tittle: "Gallery",
    subtittle: "Some photos from Our Restaurant",
    pictures: [
      {
        name: "01",
        src: "assets/img/gallery/gallery-1.jpg",
      },
      {
        name: "02",
        src: "assets/img/gallery/gallery-2.jpg",
      },
      {
        name: "03",
        src: "assets/img/gallery/gallery-3.jpg",
      },
      {
        name: "04",
        src: "assets/img/gallery/gallery-4.jpg",
      },
      {
        name: "05",
        src: "assets/img/gallery/gallery-5.jpg",
      },
      {
        name: "06",
        src: "assets/img/gallery/gallery-6.jpg",
      },
      {
        name: "07",
        src: "assets/img/gallery/gallery-7.jpg",
      },
      {
        name: "08",
        src: "assets/img/gallery/gallery-8.jpg",
      },
    ],
  },
  staff: {
    tittle: "Staff",
    subTittle: "Our Proffesional staff",
    people: [
      {
        name: "Tonni",
        ruolo: "Operations Manager",
        src: "assets/img/chefs/chefs-1.jpg",
        twitter: "",
        facebook: "",
        instagram: "",
        linkedin: "",
      },
      {
        name: "Lucia",
        ruolo: "SEO & Manager",
        src: "assets/img/chefs/chefs-2.jpg",
        twitter: "",
        facebook: "",
        instagram: "",
        linkedin: "",
      },
      {
        name: "Jamal",
        ruolo: "Master Cheff",
        src: "assets/img/chefs/chefs-3.jpg",
        twitter: "",
        facebook: "",
        instagram: "",
        linkedin: "",
      },
    ],
  },
  contact: {
    tittle: "Contact",
    subTittle: "Contact us",
    address: {
      tittle: "Location",
      address: "Via Risorgimento, 2, 22063 Cantù CO",
      href: "https://www.google.com/maps/place/Via+Risorgimento,+2,+22063+Cant%C3%B9+CO/@45.7367807,9.1274303,17z/data=!3m1!4b1!4m6!3m5!1s0x478699030a33cc91:0x9f512e8f942ee502!8m2!3d45.7367807!4d9.1274303!16s%2Fg%2F11cs6_95bp?entry=ttu",
    },
    openHours: {
      tittle: "Open Hours:",
      openHour: [
        { days: "Lunedì a Venerdì", hours: "12:00 - 15:00 e 19:00 - 23:00" },
        { days: "Sabato e Domenica", hours: "12:00 alle 21:00" },
      ],
    },
    mail: { tittle: "E-mail: ", mail: "cashilia@gmail.com" },
    phone: { tittle: "Telefono: ", phoneNumber: "+39 333 33 33 333" },
  },
  footerContent: {
    tittleFirstSection: "Link utili",
    footerMenu_01: [
      { name: "Home", hashtag: "#" },
      { name: "About", hashtag: "#about" },
      { name: "Contact", hashtag: "#contact" },
      { name: "Terms of service", hashtag: "#" },
      { name: "Privacy policy", hashtag: "#" },
    ],
    tittleSecondSection: "Partner Ufficiale",
    footerMenu_02: [
      {
        name: "Olio Zavaglia",
        hashtag: "https://oliozavaglia.it/",
        icon: "https://oliozavaglia.it/cdn/shop/files/Logo_2018_png_457x257.png?v=1613747858",
      },
    ],
    newsletter: {
      tittle: "La nostra Newsletter",
      content:
        "Tamen quem nulla quae legam multos aute sint culpa legam noster magna",
      buttonName: "Subscribe",
    },
    footerSocial: [
      { name: "Twitter", icon: "bx bxl-twitter", src: "#" },
      { name: "Facebook", icon: "bx bxl-facebook", src: "#" },
      { name: "Instagram", icon: "bx bxl-instagram", src: "#" },
      { name: "Skipe", icon: "bx bxl-skype", src: "#" },
      { name: "Linkedin", icon: "bx bxl-linkedin", src: "#" },
    ],
  },
};
*/
