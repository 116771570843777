import { FormEvent, useState } from "react";
import { MAIN_DATA } from "./content-dev";

export default function MainContent() {
  const [data, getData] = useState(MAIN_DATA);

  function alertMessage() {
    alert(
      "Servizio temporaneamente indisponibile. \n Invia una e-mail a info@cashilia.it \n opurre Chiama +39 031 497 1833 per parlare con noi!"
    );
  }

  /*async function createReservationMail(e: EventTarget) {
    try {
      const transporter = nodemailer.createTransport({
        host: "smtp.gmail.com",
        port: 465,
        secure: true,
        auth: {
          user: "lipexmania@gmail.com",
          pass: "College=70+FSF",
        },
      });

      const emailHtml = render(<Email url="https://example.com" />);

      const options = {
        from: "you@example.com",
        to: "lipexmania@gmail.com",
        subject: "hello world",
        html: emailHtml,
      };

      await transporter.sendMail(options);
    } catch (error) {
      console.log(error);
    }
  }
  */

  return (
    <div>
      <div id="topbar" className="d-flex align-items-center fixed-top">
        <div className="container d-flex justify-content-center justify-content-md-between">
          <div className="contact-info d-flex align-items-center">
            <i className="bi bi-phone d-flex align-items-center">
              <span>{data.phoneNumber}</span>
            </i>
            <i className="bi bi-card-list d-flex align-items-center ms-4">
              <span>
                <a target="_blank" href="/menu">
                  Menu'
                </a>
              </span>
            </i>
          </div>

          <div className="languages d-none d-md-flex align-items-center">
            <ul>
              {data.languages.map((name, index) => (
                <li key={index}>
                  <a href="#">{name}</a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>

      <header id="header" className="fixed-top d-flex align-items-cente">
        <div className="container-fluid container-xl d-flex align-items-center justify-content-lg-between">
          <h1 className="logo me-auto me-lg-0">
            <a href=""></a>
          </h1>
          <a href="" className="logo me-auto me-lg-0">
            <img src={data.logoLink} alt="" className="img-fluid" />
          </a>

          <nav id="navbar" className=" navbar order-last order-lg-0">
            <ul>
              {data.mainMenu.map((menuItem, index) => (
                <li key={index}>
                  <a
                    className="nav-link scrollto active"
                    href={menuItem.hashtag}
                  >
                    {menuItem.name}
                  </a>
                </li>
              ))}
            </ul>

            {/*<i className="bi bi-list" />*/}
          </nav>
          <a
            href="#book-a-table"
            className="book-a-table-btn scrollto d-none d-lg-flex"
          >
            {data.bookingButton}
          </a>
        </div>
      </header>
      <section id="hero">
        <div className="hero-container">
          <div
            id="heroCarousel"
            data-bs-interval="5000"
            className="carousel slide carousel-fade"
            data-bs-ride="carousel"
          >
            <div className="carousel-inner" role="listbox">
              {data.caroulselContent.map((item, index) => (
                <div
                  key={index}
                  className={item.activeMain}
                  style={{
                    background: `url(${item.backgroundImage}), top center`,
                    backgroundSize: "cover",
                  }}
                >
                  <div className="carousel-container">
                    <div className="carousel-content">
                      <h2 className="animate__animated animate__fadeInDown">
                        <span>{item.span}</span> {item.title}
                      </h2>
                      <p className="animate__animated animate__fadeInUp">
                        {item.mainContent}
                      </p>
                      <div>
                        {item.buttonsContent.map((buttonContent, index) => (
                          <a
                            key={index}
                            href={buttonContent.buttonLink}
                            className="btn-menu animate__animated animate__fadeInUp scrollto"
                          >
                            {buttonContent.buttonName}
                          </a>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>

            <a
              className="carousel-control-prev"
              href="#heroCarousel"
              role="button"
              data-bs-slide="prev"
            >
              <span
                className="carousel-control-prev-icon bi bi-chevron-left"
                aria-hidden="true"
              ></span>
            </a>

            <a
              className="carousel-control-next"
              href="#heroCarousel"
              role="button"
              data-bs-slide="next"
            >
              <span
                className="carousel-control-next-icon bi bi-chevron-right"
                aria-hidden="true"
              ></span>
            </a>
          </div>
        </div>
      </section>

      <main id="main">
        <section id="about" className="about">
          <div className="container" data-aos="fade-up">
            <div className="row">
              <div
                className="col-lg-6 order-1 order-lg-2"
                data-aos="zoom-in"
                data-aos-delay="100"
              >
                <div className="about-img">
                  <img src="assets/img/CM5A3185.jpeg" alt="" />
                </div>
              </div>
              <div className="col-lg-6 pt-4 pt-lg-0 order-2 order-lg-1 content">
                <h3>{data.mainSection_01.contentH3}</h3>
                <p className="fst-italic">{data.mainSection_01.contentP}</p>
                <ul>
                  {data.mainSection_01.contentChecksIndicators.map(
                    (el, index) => (
                      <li key={index}>
                        <i className="bi bi-check-circle"></i>
                        {el}
                      </li>
                    )
                  )}
                </ul>
              </div>
            </div>
            <p>{data.mainSection_01.lastContent}</p>
          </div>
        </section>

        <section id="why-us" className="why-us">
          <div className="container" data-aos="fade-up">
            <div className="section-title">
              <h2>{data.whyUsSection.tittle}</h2>
              <p>{data.whyUsSection.subTittle}</p>
            </div>

            <div className="row">
              {data.whyUsSection.boxContent.map((boxContent, index) => (
                <div className="col-lg-4 mt-4 mt-lg-0" key={index}>
                  <div className="box" data-aos="zoom-in" data-aos-delay="100">
                    <span>{boxContent.tittle}</span>
                    <h4>{boxContent.subTittle}</h4>
                    <p>{boxContent.content}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>

        {/*
        <section id="menu" className="menu section-bg">
          <div className="container" data-aos="fade-up">
            <div className="section-title">
              <h2>{data.menuSection.tittle}</h2>
              <p>{data.menuSection.subTittle}</p>
            </div>

            <div className="row" data-aos="fade-up" data-aos-delay="100">
              <div className="col-lg-12 d-flex justify-content-center">
                <ul id="menu-flters">
                  <li data-filter="*" className="filter-active">
                    {data.menuSection.mainFilterAll}
                  </li>
                  {data.menuSection.categoriesNames.map((category, index) => (
                    <li data-filter={category.filterName} key={index}>
                      {category.categoryName}
                    </li>
                  ))}
                </ul>
              </div>
            </div>

            <div
              className="row menu-container"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              {data.menuSection.menu_items.map((menuItem, index) => (
                <div className={menuItem.className} key={index}>
                  <img
                    src={menuItem.image}
                    className="menu-img"
                    alt={menuItem.alt}
                  />
                  <div className="menu-content">
                    <a href={menuItem.href}>{menuItem.name}</a>
                    <span>{menuItem.price}</span>
                  </div>
                  <div className="menu-ingredients">{menuItem.ingredients}</div>
                </div>
              ))}
            </div>
          </div>
        </section>
       */}

        <section id="specials" className="specials">
          <div className="container" data-aos="fade-up">
            <div className="section-title">
              <h2>{data.specials.tittle}</h2>
              <p>{data.specials.subTittle}</p>
            </div>

            <div className="row" data-aos="fade-up" data-aos-delay="100">
              <div className="col-lg-3">
                <ul className="nav nav-tabs flex-column">
                  {data.specials.tabItem.itemTittles.map((tabItem, index) => (
                    <li className="nav-item" key={index}>
                      <a
                        className={tabItem.className}
                        data-bs-toggle="tab"
                        href={tabItem.href}
                      >
                        {tabItem.text}{" "}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="col-lg-9 mt-4 mt-lg-0">
                <div className="tab-content">
                  {data.specials.tabItem.itemContents.map((tabItem, index) => (
                    <div
                      className={tabItem.className}
                      id={tabItem.id}
                      key={index}
                    >
                      <div className="row">
                        <div className={tabItem.inestedClassName}>
                          <h3>{tabItem.h3El}</h3>
                          <p className="fst-italic">{tabItem.firstContent}</p>
                          <p>{tabItem.mainContent}</p>
                        </div>
                        <div className={tabItem.lastInestedClassName}>
                          <img
                            src={tabItem.imageSrc}
                            alt={tabItem.imageAlt}
                            className="img-fluid"
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </section>

        <section
          id="events"
          className="events"
          style={{ background: data.events.background }}
        >
          <div className="container">
            <div className="section-title">
              <h2>{data.events.tittle}</h2>
              <p>{data.events.subTittle}</p>
            </div>

            <div className="swiper-wrapper">
              <div className="swiper-slide">
                <div className="row event-item">
                  <div className="col-lg-6">
                    <img src={data.events.image} className="img-fluid" alt="" />
                  </div>
                  <div className="col-lg-6 pt-4 pt-lg-0 content">
                    <h3>{data.events.mainTittle}</h3>
                    <div className="price">
                      <p>
                        <span>{data.events.price}</span>
                      </p>
                    </div>
                    <p className="fst-italic">{data.events.firstContent}</p>
                    <ul>
                      {data.events.liContent.map((el, index) => (
                        <li key={index}>
                          <i className="bi bi-check-circled"></i>
                          {el}
                        </li>
                      ))}
                    </ul>
                    <p>{data.events.lastContent}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="book-a-table" className="book-a-table">
          <div className="container" data-aos="fade-up">
            <div className="section-title">
              <h2>Reservation</h2>
              <p>Prenota un Tavolo</p>
            </div>

            <form
              role="form"
              className="php-email-form"
              data-aos="fade-up"
              data-aos-delay="100"
              onSubmit={(e) => {
                alertMessage();
              }}
            >
              <div className="row">
                <div className="col-lg-4 col-md-6 form-group">
                  <input
                    disabled
                    type="text"
                    name="name"
                    className="form-control"
                    id="name"
                    placeholder="Your Name"
                    data-rule="minlen:4"
                    data-msg="Please enter at least 4 chars"
                  />
                  <div className="validate"></div>
                </div>
                <div className="col-lg-4 col-md-6 form-group mt-3 mt-md-0">
                  <input
                    disabled
                    type="email"
                    className="form-control"
                    name="email"
                    id="email"
                    placeholder="Your Email"
                    data-rule="email"
                    data-msg="Please enter a valid email"
                  />
                  <div className="validate"></div>
                </div>
                <div className="col-lg-4 col-md-6 form-group mt-3 mt-md-0">
                  <input
                    disabled
                    type="text"
                    className="form-control"
                    name="phone"
                    id="phone"
                    placeholder="Your Phone"
                    data-rule="minlen:4"
                    data-msg="Please enter at least 4 chars"
                  />
                  <div className="validate"></div>
                </div>
                <div className="col-lg-4 col-md-6 form-group mt-3">
                  <input
                    disabled
                    type="text"
                    name="date"
                    className="form-control"
                    id="date"
                    placeholder="Date"
                    data-rule="minlen:4"
                    data-msg="Please enter at least 4 chars"
                  />
                  <div className="validate"></div>
                </div>
                <div className="col-lg-4 col-md-6 form-group mt-3">
                  <input
                    disabled
                    type="text"
                    className="form-control"
                    name="time"
                    id="time"
                    placeholder="Time"
                    data-rule="minlen:4"
                    data-msg="Please enter at least 4 chars"
                  />
                  <div className="validate"></div>
                </div>
                <div className="col-lg-4 col-md-6 form-group mt-3">
                  <input
                    disabled
                    type="number"
                    className="form-control"
                    name="people"
                    id="people"
                    placeholder="# of people"
                    data-rule="minlen:1"
                    data-msg="Please enter at least 1 chars"
                  />
                  <div className="validate"></div>
                </div>
              </div>
              <div className="form-group mt-3">
                <textarea
                  disabled
                  className="form-control"
                  name="message"
                  rows={5}
                  placeholder="Message"
                ></textarea>
                <div className="validate"></div>
              </div>
              <div className="mb-3">
                <div className="loading">Loading</div>
                <div className="error-message"></div>
                <div className="sent-message">
                  Your booking request was sent. We will call back or send an
                  Email to confirm your reservation. Thank you!
                </div>
              </div>
              <div className="text-center">
                <button type="submit">Prenota Ora</button>
              </div>
            </form>
          </div>
        </section>

        <section id="gallery" className="gallery">
          <div className="container">
            <div className="section-title">
              <h2>{data.gallery.tittle}</h2>
              <p>{data.gallery.subtittle}</p>
            </div>
          </div>

          <div
            className="container-fluid"
            data-aos="fade-up"
            data-aos-delay="100"
          >
            <div className="row g-0">
              {data.gallery.pictures.map((picture, index) => (
                <div className="col-lg-3 col-md-4" key={index}>
                  <div className="gallery-item">
                    <a
                      href="https://www.instagram.com/ca.shilia/?igsh=YTQwZjQ0NmI0OA%3D%3D"
                      target="_blank"
                      className="gallery-lightbox"
                      data-gall="gallery-item"
                    >
                      <img
                        src={picture.src}
                        alt={picture.name}
                        className="img-fluid"
                      />
                    </a>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>

        {/*

        <section id="staff" className="staff">
          <div className="container" data-aos="fade-up">
            <div className="section-title">
              <h2>{data.staff.tittle}</h2>
              <p>{data.staff.subTittle}</p>
            </div>

            <div className="row">
              {data.staff.people.map((person, index) => (
                <div className="col-lg-4 col-md-6" key={index}>
                  <div
                    className="member"
                    data-aos="zoom-in"
                    data-aos-delay="300"
                  >
                    <img
                      src={person.src}
                      className="img-fluid"
                      alt={person.name}
                    />
                    <div className="member-info">
                      <div className="member-info-content">
                        <h4>{person.name}</h4>
                        <span>{person.ruolo}</span>
                      </div>
                      <div className="social">
                        <a href={person.twitter}>
                          <i className="bi bi-twitter"></i>
                        </a>
                        <a href={person.facebook}>
                          <i className="bi bi-facebook"></i>
                        </a>
                        <a href={person.instagram}>
                          <i className="bi bi-instagram"></i>
                        </a>
                        <a href={person.linkedin}>
                          <i className="bi bi-linkedin"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
        */}

        <section id="contact" className="contact">
          <div className="container" data-aos="fade-up">
            <div className="section-title">
              <h2>{data.contact.tittle}</h2>
              <p>{data.contact.subTittle}</p>
            </div>
          </div>

          <div className="container" data-aos="fade-up">
            <div className="row mt-5">
              <div className="col-lg-4">
                <div className="info">
                  <div className="address">
                    <i className="bi bi-geo-alt"></i>
                    <h4>{data.contact.address.tittle}</h4>
                    <p>
                      <a target="_blank" href={data.contact.address.href}>
                        {data.contact.address.address}
                      </a>
                    </p>
                  </div>

                  <div className="open-hours">
                    <i className="bi bi-clock"></i>
                    <h4>{data.contact.openHours.tittle}</h4>
                    {data.contact.openHours.openHour.map((openHour, index) => (
                      <p key={index}>
                        <br />
                        {openHour.days}
                        <br />
                        {openHour.hours}
                      </p>
                    ))}
                  </div>

                  <div className="email">
                    <i className="bi bi-envelope"></i>
                    <h4>{data.contact.mail.tittle}</h4>
                    <p>{data.contact.mail.mail}</p>
                  </div>

                  <div className="phone">
                    <i className="bi bi-phone"></i>
                    <h4>{data.contact.phone.tittle}</h4>
                    <p>{data.contact.phone.phoneNumber}</p>
                  </div>
                </div>
              </div>

              <div className="col-lg-8 mt-5 mt-lg-0">
                <form
                  onSubmit={(e) => {
                    alertMessage();
                  }}
                  role="form"
                  className="php-email-form"
                >
                  <div className="row">
                    <div className="col-md-6 form-group">
                      <input
                        disabled
                        type="text"
                        name="name"
                        className="form-control"
                        id="name"
                        placeholder="Your Name"
                        required
                      />
                    </div>
                    <div className="col-md-6 form-group mt-3 mt-md-0">
                      <input
                        disabled
                        type="email"
                        className="form-control"
                        name="email"
                        id="email"
                        placeholder="Your Email"
                        required
                      />
                    </div>
                  </div>
                  <div className="form-group mt-3">
                    <input
                      disabled
                      type="text"
                      className="form-control"
                      name="subject"
                      id="subject"
                      placeholder="Subject"
                      required
                    />
                  </div>
                  <div className="form-group mt-3">
                    <textarea
                      disabled
                      className="form-control"
                      name="message"
                      rows={8}
                      placeholder="Message"
                      required
                    ></textarea>
                  </div>
                  <div className="my-3">
                    <div className="loading">Loading</div>
                    <div className="error-message"></div>
                    <div className="sent-message">
                      Your message has been sent. Thank you!
                    </div>
                  </div>
                  <div className="text-center">
                    <button type="submit">Invia</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </main>

      <footer id="footer">
        <div className="footer-top">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-md-6">
                <div className="footer-info">
                  <h3>
                    <a href="/" className="logo me-auto me-lg-0">
                      <img
                        src={data.logoLink}
                        alt=""
                        className="img-fluid"
                        style={{ width: "150px", height: "50px" }}
                      />
                    </a>
                  </h3>
                  <p />
                  <a target="_blank" href={data.contact.address.href}>
                    {" "}
                    {data.contact.address.address}{" "}
                  </a>
                  <br />
                  <br />
                  <strong>{data.contact.phone.tittle}</strong>
                  {data.contact.phone.phoneNumber}
                  <br />
                  <br />
                  <strong>{data.contact.mail.tittle}</strong>{" "}
                  {data.contact.mail.mail}
                  <br />
                  <br />
                  <p />
                  <div className="social-links mt-3">
                    {data.footerContent.footerSocial.map((social, index) => (
                      <a
                        target="_blank"
                        href={social.src}
                        className={social.name}
                        key={index}
                      >
                        <i className={social.icon}></i>
                      </a>
                    ))}
                  </div>
                </div>
              </div>

              <div className="col-lg-2 col-md-6 footer-links">
                <h4>{data.footerContent.tittleFirstSection}</h4>
                <ul>
                  {data.footerContent.footerMenu_01.map((item, index) => (
                    <li key={index}>
                      <i className="bx bx-chevron-right"></i>{" "}
                      <a href={item.hashtag}>{item.name}</a>
                    </li>
                  ))}
                </ul>
              </div>

              <div className="col-lg-3 col-md-6 footer-links">
                <h4>{data.footerContent.tittleSecondSection}</h4>
                <ul>
                  {data.footerContent.footerMenu_02.map((item, index) => (
                    <li key={index}>
                      <i className="bx bx-chevron-right"></i>{" "}
                      <a target="_blank" href={item.hashtag}>
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>

              <div className="col-lg-4 col-md-6 footer-newsletter">
                <h4>{data.footerContent.newsletter.tittle}</h4>
                <p>{data.footerContent.newsletter.content}</p>
                <form
                  onSubmit={(e) => {
                    alertMessage();
                  }}
                >
                  <input disabled type="email" name="email" />
                  <input
                    type="submit"
                    value={data.footerContent.newsletter.buttonName}
                  />
                </form>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="copyright">
            &copy; Copyright{" "}
            <strong>
              <span>Ca' Shilia</span>
            </strong>
            . All Rights Reserved
          </div>
          <div className="credits">
            <span>
              Designed & Dev by{" "}
              <a
                target="_blank"
                href="https://www.linkedin.com/in/felipe-s-0b7365201/"
              >
                Felipe SF :-)
              </a>
            </span>
          </div>
        </div>
      </footer>
    </div>
  );
}
