import React from "react";
import { MAIN_DATA } from "../content-dev";
export default class Form_001 extends React.Component<any, any> {
  constructor(props: {} | Readonly<{}>) {
    super(props);
    this.state = MAIN_DATA;
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event: { target: { value: any } }, type: String) {
    switch (type) {
      case "phoneNumber":
        //var obj = { [type]: event.target.value };
        // this.setState({ [type]: event.target.value });
        break;
      case "lunchTime":
        this.setState({ lunchTime: event.target.value });
        break;
      case "dinnerTime":
        this.setState({ dinnerTime: event.target.value });
        break;
      default:
        break;
    }
  }

  handleSubmit(this: any, event: { preventDefault: () => void }) {
    this.props.onSubmitData({
      lang: ["pt", "it"],
      dinnerTime: this.state.openTime,
      logoLink: this.state.logo,
      lunchTime: this.state.lunchTime,
      phoneNumber: this.state.phoneNumber,
    });
    event.preventDefault();
  }

  render() {
    return (
      <div className="accordion accordion-flush" id="accordionFlushExample">
        <div className="accordion-item">
          <h2 className="accordion-header" id="flush-headingOne">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#flush-collapseOne"
              aria-expanded="false"
              aria-controls="flush-collapseOne"
            >
              Logo principale, Telefono, Orari, Lingue.
            </button>
          </h2>
          <div
            id="flush-collapseOne"
            className="accordion-collapse collapse"
            aria-labelledby="flush-headingOne"
            data-bs-parent="#accordionFlushExample"
          >
            <div className="accordion-body">
              <form className="row g-3" onSubmit={this.handleSubmit}>
                <div className="col-md-6">
                  <label htmlFor="inputEmail4" className="form-label">
                    Logo link:
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="inputEmail4"
                    value={this.state.lunchTime}
                    onChange={(e) => {
                      this.handleChange(e, "lunchTime");
                    }}
                  />
                </div>
                <div className="col-md-6">
                  <label htmlFor="inputPassword4" className="form-label">
                    Telefono:
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="inputPassword4"
                    value={this.state.phoneNumber}
                    onChange={(e) => {
                      this.handleChange(e, "phoneNumber");
                    }}
                  />
                </div>
                <div className="col-12">
                  <label htmlFor="inputAddress" className="form-label">
                    Orari di apertura:
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="inputAddress"
                    placeholder="domedica chiuso"
                    value={this.state.lunchTime}
                    onChange={(e) => {
                      this.handleChange(e, "openTime");
                    }}
                  />
                </div>

                <div className="col-12">
                  <button type="submit" className="btn btn-primary">
                    Salva
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
