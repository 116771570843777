import React, { useState } from "react";
import { signInWithEmailAndPassword } from "firebase/auth";
import { getAuth } from "firebase/auth";
import { app } from "../src/firebase-config";
export const auth = getAuth(app);

export default class LoginPage extends React.Component<any, any> {
  constructor(props: {} | Readonly<{}>) {
    super(props);
    this.state = { pass: "", user: "" };
    this.handleChangePassword = this.handleChangePassword.bind(this);
    this.handleChangeUser = this.handleChangeUser.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChangePassword(this: any, event: { target: { value: any } }) {
    this.setState({ pass: event.target.value });
  }

  handleChangeUser(this: any, event: { target: { value: any } }) {
    this.setState({ user: event.target.value });
  }

  handleSubmit(e: { preventDefault: () => void }) {
    /*if (this.state.user === "cashilia_admin" && this.state.pass === "1234") {
      console.log(this.state.user + this.state.pass);
      window.location.href = "/dashboard_cashilia_cantu";
    }
    e.preventDefault();
    */

    e.preventDefault();
    signInWithEmailAndPassword(auth, this.state.user, this.state.pass)
      .then(async (userCredential) => {
        // Signed in
        const user = userCredential.user;
        const token = await user.getIdToken();
        console.log("TokenID", token);

        sessionStorage.setItem("Auth Token", token);

        window.location.href = "/dashboard_cashilia_cantu";
        console.log(user);
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(errorCode, errorMessage);
      });
  }

  /*Login = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const onLogin = (e: { preventDefault: () => void }) => {
      e.preventDefault();
      signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
          // Signed in
          const user = userCredential.user;
          navigate("/home");
          console.log(user);
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          console.log(errorCode, errorMessage);
        });
    };
  };
*/
  render() {
    return (
      <section className="vh-100 dashboard">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-6 text-black">
              <div className="px-5 ms-xl-4">
                <i
                  className="fas fa-crow fa-2x me-3 pt-5 mt-xl-4"
                  style={{ color: "#709085" }}
                ></i>
                <span className="h1 fw-bold mb-0">Ca' Shilia</span>
              </div>

              <div className="d-flex align-items-center h-custom-2 px-5 ms-xl-4 mt-5 pt-5 pt-xl-0 mt-xl-n5">
                <form style={{ width: "23rem" }} onSubmit={this.handleSubmit}>
                  <h3
                    className="fw-normal mb-3 pb-3"
                    style={{ letterSpacing: "1px" }}
                  >
                    Management Log in:
                  </h3>

                  <div className="form-outline mb-4">
                    <input
                      type="text"
                      id="form2Example18"
                      className="form-control form-control-lg"
                      value={this.state.user}
                      onChange={this.handleChangeUser}
                    />
                    <label className="form-label">Username</label>
                  </div>

                  <div className="form-outline mb-4">
                    <input
                      type="password"
                      id="form2Example28"
                      className="form-control form-control-lg"
                      value={this.state.pass}
                      onChange={this.handleChangePassword}
                    />
                    <label className="form-label">Password</label>
                  </div>

                  <div className="pt-1 mb-4">
                    <button
                      className="btn btn-info btn-lg btn-block"
                      type="submit"
                      value="Submit"
                    >
                      Login
                    </button>
                  </div>

                  {/*<p className="small mb-5 pb-lg-2"><a className="text-muted" href="#!">Forgot password?</a></p>
           <p>Don't have an account? <a href="#!" className="link-info">Register here</a></p> */}
                </form>
              </div>
            </div>
            <div className="col-sm-6 px-0 d-none d-sm-block">
              <img
                src="assets/img/testaMoro.jpg"
                alt="Login image"
                className="w-100 vh-100"
                style={{ objectFit: "cover", objectPosition: "left" }}
              />
            </div>
          </div>
        </div>
      </section>
    );
  }
}
