import { Routes, Route } from "react-router-dom";
import MainContent from "./main-content";
import LoginPage from "./login-page";
import AOS from "aos";
import "aos/dist/aos.css";
import Dashboard from "./dashboard";
import MenuPage from "./menus/menu";
import TempPage from "./temp-wip";
import LunchMenu from "./menus/sub-menus/lunch-menu";
import DinnerMenu from "./menus/sub-menus/dinner-menu";
import CartaMenu from "./menus/sub-menus/carta-menu";
import DrinksMenu from "./menus/sub-menus/drinks-menu";
import { collection, getDocs } from "firebase/firestore";
import { db } from "./firebase-config";
import LunchMenuLayer from "./menus/sub-menus/lunch-main-layer";
import LunchMenuPoke from "./menus/sub-menus/lunch-menu-poke";
import LunchMenuPanini from "./menus/sub-menus/lunch-menu-panini";

AOS.init();

const App = () => {
  //Retrieving Menu-text-content Data from DB Firebase;
  try {
    const colRef = collection(db, "menu");
    getDocs(colRef).then((menu: any) => {
      const projects: any[] = [];
      menu.docs.map((doc: { id: any; data: () => any }) => {
        //console.log(doc.id);
        const menU = { ...doc.data(), id: doc.id };
        projects.push(menU);
      });
      //console.log(projects);
      if (projects.length >= 1) {
        localStorage.setItem("menu", JSON.stringify(projects));
      }
    });

    // this will re render the view with new data
  } catch (err) {
    console.log(err);
  }

  //Retrieveing Website text-content from DB;
  /**  try {
     const colRef = collection(db, "todos");
     getDocs(colRef).then((todos: any) => {
       const projects_: any[] = [];
       menu.docs.map((doc: { id: any; data: () => any }) => {
         //console.log(doc.id);
         const menU = { ...doc.data(), id: doc.id };
         projects_.push(menU);
       });
       //console.log(projects);
       if (projects_.length >= 1) {
         localStorage.setItem("todos", JSON.stringify(projects_));
       }
     });

     // this will re render the view with new data
   } catch (err) {
     console.log(err);
   }
   */
  return (
    <>
      <Routes>
        <Route path="/wip" element={<TempPage />} />
        <Route path="/" element={<MainContent />} />
        <Route path="/dashboard" element={<LoginPage />} />
        <Route path="/dashboard_cashilia_cantu" element={<Dashboard />} />
        <Route path="/menu" element={<MenuPage />} />
        <Route path="/pranzo" element={<LunchMenuLayer />} />
        <Route path="/pranzoCarta" element={<LunchMenu />} />
        <Route path="/pranzoPoke" element={<LunchMenuPoke />} />
        <Route path="/pranzoPanini" element={<LunchMenuPanini />} />
        <Route path="/cena" element={<DinnerMenu />} />
        <Route path="/carta" element={<CartaMenu />} />
        <Route path="/beverage" element={<DrinksMenu />} />
      </Routes>
    </>
  );
};

export default App;
