import React from "react";
//import { LUNCH } from "../lunch";

export default class LunchMenu extends React.Component<any, any> {
  constructor(props: {} | Readonly<{}>) {
    super(props);
    const menu = JSON.parse(localStorage.getItem("menu")!);
    this.state = { menus: [menu[1]] };
  }

  swichContent(
    content: { type: string; content: string },
    indexContent: number
  ) {
    switch (content.type) {
      case "categorie":
        return (
          <h5 key={indexContent} className="fw-bold fs-3">
            <br />
            <hr />
            {content.content}
            <hr />
          </h5>
        );
      case "description":
        return (
          <h6 key={indexContent} className="fw-bolder fs-6">
            {content.content}
          </h6>
        );
      case "note":
        return (
          <span key={indexContent} className="fst-italic fs-6">
            {content.content}
            <br />
            <hr />
          </span>
        );
      default:
        break;
    }
  }

  render() {
    return (
      <section className="menu-lunch">
        <a href="/" className="image-container">
          <img
            src="../assets/img/logo_main.png"
            alt=""
            className="image-container mb-2"
          />
        </a>
        <br />
        <h4 className="text-center mt-2">
          <button type="button" className="btn btn-outline-warning m-1">
            <a href="/pranzo"> Menu Pranzo</a>
          </button>
          <button type="button" className="btn btn-outline-warning m-1">
            <a href="/menu"> Menu Principale</a>
          </button>
        </h4>

        {this.state.menus.map(
          (
            menu: {
              name: string;
              contents: Array<{ type: string; content: string }>;
            },
            indexMenu: number
          ) => (
            <div className="transbox" key={indexMenu}>
              <h3 className="text-center m-2">{menu.name}</h3>
              {menu.contents.map(
                (
                  content: { type: string; content: string },
                  indexContent: number
                ) => (
                  <div className="text-center" key={indexContent}>
                    {this.swichContent(content, indexContent)}
                  </div>
                )
              )}
            </div>
          )
        )}
        <br />
      </section>
    );
  }
}
