import React from "react";
import { listAll, ref, uploadBytes } from "firebase/storage";
import { storage } from "../firebase-config";

export default class PictureUpload extends React.Component<any, any> {
  constructor(props: {} | Readonly<{}>) {
    super(props);
    this.state = {};
  }

  deletePicture(event: {}): void {
    //todo
  }

  handleChange(event: any) {
    /*
    //console.log(event.target.files[0]);
    const storageRef = ref(storage, `newfolder/${event.target.files[0].name}`);
    uploadBytes(storageRef, event.target.files[0]).then((snapshot) => {
      console.log("Uploaded a blob or file!", snapshot);
      alert(` Caricata immagine:  ${snapshot.metadata.name}`);
      // Find all the prefixes and items.
      listAll(ref(storage, "newfolder/"))
        .then((res) => {
          res.prefixes.forEach((folderRef) => {
            //console.log("folder ref", folderRef);
            // All the prefixes under listRef.
            // You may call listAll() recursively on them.
          });
          res.items.forEach((itemRef) => {
            console.log(itemRef.name);
            // All the items under listRef.
          });
          window.location.reload();
        })
        .catch((error) => {
          // Uh-oh, an error occurred!
        });
    });
    */
  }

  upload() {
    const storageRef = ref(storage, this.state.input);
    uploadBytes(storageRef, this.state.input).then((snapshot) => {
      console.log("Uploaded a blob or file!");
      this.setState({ input: "" });
    });

    /*
    fetch("gs://cashilia.appspot.com/addPicture", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        firstParam: this.state.input,
        secondParam: async (
          req: { file: any },
          res: { send: (arg0: string) => void }
        ) => {
          const file = req.file;
          const imageRef = ref(storage, file.originalname);
          const metatype = {
            contentType: file.mimetype,
            name: file.originalname,
          };
          await uploadBytes(imageRef, file.buffer, metatype)
            .then((snapshot: any) => {
              res.send("uploaded!");
            })
            .catch((error: { message: any }) => console.log(error.message));
        },
      }),
    });
    */
  }

  render() {
    return (
      <div className="accordion accordion-flush" id="accordionFlushExample">
        <div className="accordion-item">
          <h2 className="accordion-header" id="flush-headingOne">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#flush-collapseTwo"
              aria-expanded="false"
              aria-controls="flush-collapseTwo"
            >
              Pictures Upload:
            </button>
          </h2>
          <div
            id="flush-collapseTwo"
            className="accordion-collapse collapse"
            aria-labelledby="flush-headingOne"
            data-bs-parent="#accordionFlushExample"
          >
            <div className="accordion-body">
              <div className="input-group mb-3">
                <div className="">
                  <input
                    type="file"
                    className=""
                    id=""
                    title=""
                    onChange={(e) => this.handleChange(e)}
                  />
                  <label
                    className=""
                    htmlFor=""
                    title=""
                    style={{ fontSize: "0px" }}
                  >
                    {this.state.input}{" "}
                  </label>
                </div>
              </div>
              <ul className="list-group">
                <li className="list-group-item">
                  <i
                    className="bi bi-trash pull-left pl-2"
                    typeof="button"
                    onClick={this.deletePicture}
                  >
                    {" "}
                  </i>
                  img.pnj
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
