import React from "react";

export default class TempPage extends React.Component<any, any> {
  constructor(props: {} | Readonly<{}>) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <section className="vh-100 wip">
        <h3 className="text-center">Stiamo arrivando!</h3>
      </section>
    );
  }
}
