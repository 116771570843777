import React from "react";
//import { DRINKS } from "../menus/drinks";

export default class MenuSettings extends React.Component<any, any> {
  state = { menus: Array() };

  constructor(props: {} | Readonly<{}>) {
    super(props);
    this.state = { menus: [] };
    /*this.state = {
      menus: [
        {
          name: "Pranzo",
          contents: [
            { type: "note", content: "" },
            { type: "categorie", content: "" },
            { type: "description", content: "" },
            { type: "note", content: "" },
          ],
        },
        {
          name: "cena",
          contents: [
            { type: "note", content: "" },
            { type: "categorie", content: "" },
            { type: "description", content: "" },
            { type: "note", content: "" },
          ],
        },
        {
          name: "Take Away",
          contents: [
            { type: "note", content: "" },
            { type: "categorie", content: "" },
            { type: "description", content: "" },
            { type: "note", content: "" },
          ],
        },
        {
          name: "Capodano",
          contents: [
            { type: "note", content: "" },
            { type: "categorie", content: "" },
            { type: "description", content: "" },
            { type: "note", content: "" },
          ],
        },
      ],
    };
    */
  }

  componentDidMount(): void {
    // this.setState({ menus: [] });
  }

  componentDidUpdate(): void {
    if (this.state.menus.length === 0) {
      if (this.props.stateP.length !== 0) {
        this.setState({ menus: this.props.stateP });
      } else {
        this.setState({ menus: [] });
      }
    }
  }

  handleChanges(
    e: React.ChangeEvent<HTMLInputElement>,
    indexContent: number,
    indexMenu: number
  ) {
    const newContent = this.state.menus.map(
      (
        el: {
          name: string;
          contents: Array<{ type: string; content: string }>;
        },
        indexMenu_: number
      ) => {
        if (indexMenu_ == indexMenu) {
          el.contents.map(
            (
              content: { type: string; content: string },
              indexContent_: number
            ) => {
              if (indexContent == indexContent_) {
                content.content = e.target.value;
              }
            }
          );
        } else {
          return el;
        }
      }
    );
    this.setState({ menu: newContent });
    e.preventDefault();
  }

  handleChangesType(
    e: React.ChangeEvent<HTMLInputElement>,
    indexContent: number,
    indexMenu: number
  ) {
    const newContent = this.state.menus.map(
      (
        el: {
          name: string;
          contents: Array<{ type: string; content: string }>;
        },
        indexMenu_: number
      ) => {
        if (indexMenu_ == indexMenu) {
          el.contents.map(
            (
              content: { type: string; content: string },
              indexContent_: number
            ) => {
              if (indexContent == indexContent_) {
                content.type = e.target.value;
              }
            }
          );
        } else {
          return el;
        }
      }
    );
    this.setState({ menu: newContent });
    e.preventDefault();
  }

  handleChangeName(
    e: React.ChangeEvent<HTMLInputElement>,
    _el: {
      id: string;
      name: string;
      contents: Array<{ type: string; content: string }>;
    },
    index_: number
  ) {
    const newState = this.state.menus.map(
      (
        el: {
          id: string;
          name: string;
          contents: Array<{ type: string; content: string }>;
        },
        index: number
      ) => {
        if (index == index_) {
          el.name = e.target.value;
          return el;
        } else {
          // The rest haven't changed
          return el;
        }
      }
    );
    this.setState({ menu: newState });
    e.preventDefault();
  }

  newMenu(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    /* const newMenuData: { name: string; contents: Array<string> } = {
      name: "New Menu",
      contents: [],
    };
    //const newMenuData = DRINKS;

    const newMenu = [this.state.menus.push(newMenuData)];
    console.log(newMenu);
    this.setState(newMenu);
    this.props.newMenu(newMenuData);
    */
    alert("Funzione disponibile a breve!");
    e.preventDefault();
  }

  handleSubmit(
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    idMenu: string
  ) {
    this.props.onSubmitMenu(this.state, idMenu);
    e.preventDefault();
  }

  addContent(
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    indexMenu: number,
    indexContent: number,
    type: string
  ) {
    const newMenu = this.state.menus.map(
      (
        menu: {
          name: string;
          contents: Array<{ type: string; content: string }>;
        },
        indexMenu_: number
      ) => {
        if (indexMenu == indexMenu_) {
          menu.contents.push({ type: type, content: "" });
          return menu;
        } else return menu;
      }
    );
    this.setState({ menus: newMenu });
    e.preventDefault();
  }

  deleteMenu(e: React.MouseEvent<HTMLButtonElement, MouseEvent>, ID: string) {
    if (ID) {
      // this.props.deleteMenu(ID);
      alert("Chiedi al admin del server la cancellazione del menu'");

      // 2nd parameter means remove one item only
    }

    e.preventDefault();
  }

  deleteCampo(
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    id: string,
    indexContent: number
  ): void {
    const newContent = this.state.menus.filter((menu) => menu.id === id)[0]
      .contents;
    newContent.splice(indexContent, 1);
    this.setState({ menu: newContent });
    e.preventDefault();
  }

  addCampo(
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    id: string,
    indexContent: number
  ): void {
    const newContent = this.state.menus.filter((menu) => menu.id === id)[0]
      .contents;
    newContent.splice(indexContent + 1, 0, { type: "", content: "" });
    console.log("new content", newContent);
    this.setState({ menu: newContent });
    e.preventDefault();
  }

  handleSubmit_(indexMenu: number) {
    throw new Error("Method not implemented.");
  }

  render() {
    return (
      <div className="accordion accordion-flush" id="accordionFlushExample">
        <div className="accordion-item" id="menu">
          <h2 className="accordion-header" id="flush-headingOne">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#flush-collapseTre"
              aria-expanded="false"
              aria-controls="flush-collapseTre"
            >
              Menu edit.
            </button>
          </h2>
          <div
            id="flush-collapseTre"
            className="accordion-collapse collapse"
            aria-labelledby="flush-headingOne"
            data-bs-parent="#accordionFlushExample"
          >
            <hr />
            <button
              type="button"
              className="btn btn-primary btn-lg m-2"
              onClick={(e) => {
                this.newMenu(e);
              }}
            >
              New Menu
            </button>
            <hr />

            {this.state.menus.map(
              (
                el: {
                  id: string;
                  name: string;
                  contents: Array<{ type: string; content: string }>;
                },
                indexMenu: number
              ) => (
                <div
                  className="accordion-body"
                  key={indexMenu}
                  style={{ borderStyle: "double" }}
                >
                  <h3>{el.name}</h3>
                  {/*<button
                    type="button"
                    className="btn btn-outline-primary m-2"
                    onClick={(e) => {
                      this.addContent(e, indexMenu, indexMenu, "Note");
                    }}
                  >
                    Nuova Nota
                  </button>
                  <button
                    type="button"
                    className="btn btn-outline-secondary m-2"
                    onClick={(e) => {
                      this.addContent(e, indexMenu, 0, "Categoria");
                    }}
                  >
                    Nuova categoria
                  </button>
                  <button
                    type="button"
                    className="btn btn-outline-info m-2"
                    onClick={(e) => {
                      this.addContent(e, indexMenu, 1, "Descrizione");
                    }}
                  >
                    Nuova Descrizione
                  </button>
                  */}
                  <button
                    type="button"
                    className="btn btn-outline-danger m-2"
                    onClick={(e) => this.deleteMenu(e, el.id)}
                  >
                    Delete Menu
                  </button>
                  <button
                    type="button"
                    className="btn btn-outline-success m-2"
                    onClick={(e) => {
                      this.handleSubmit(e, el.id);
                    }}
                  >
                    Salva/ Update
                  </button>
                  <form className="row g-3">
                    <div className="col-12">
                      <label htmlFor="inputAddress" className="form-label">
                        Menu name:
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="inputAddress"
                        placeholder=""
                        value={el.name}
                        onChange={(e) => {
                          this.handleChangeName(e, el, indexMenu);
                        }}
                      />
                    </div>

                    {el.contents.map(
                      (
                        content: { type: string; content: string },
                        indexContent: number
                      ) => (
                        <div
                          className="col-12"
                          key={indexContent}
                          style={{
                            backgroundColor: "#F2F2F2",
                            borderStyle: "double",
                          }}
                        >
                          <input
                            type="text"
                            className="form-control mt-2"
                            id="inputLabel"
                            placeholder=""
                            value={content.type}
                            onChange={(e) => {
                              this.handleChangesType(
                                e,
                                indexContent,
                                indexMenu
                              );
                            }}
                          />

                          {/*<label htmlFor="inputAddress" className="form-label">
                            {content.type}
                          </label>*/}
                          <input
                            type="text"
                            className="form-control"
                            id="inputAddress"
                            placeholder=""
                            value={content.content}
                            onChange={(e) => {
                              this.handleChanges(e, indexContent, indexMenu);
                            }}
                          />

                          <button
                            type="button"
                            className="btn btn-outline-info m-2"
                            onClick={(e) => {
                              this.addCampo(e, el.id, indexContent);
                            }}
                          >
                            Nuovo Campo
                          </button>
                          <button
                            type="button"
                            className="btn btn-outline-danger m-2"
                            onClick={(e) =>
                              this.deleteCampo(e, el.id, indexContent)
                            }
                          >
                            Delete Campo
                          </button>
                        </div>
                      )
                    )}

                    <div className="col-12">
                      <button
                        type="submit"
                        className="btn btn-primary"
                        onClick={(e) => {
                          this.handleSubmit(e, el.id);
                        }}
                      >
                        Salva
                      </button>
                    </div>
                  </form>
                  <hr /> <hr /> <hr />
                </div>
              )
            )}
          </div>
        </div>
      </div>
    );
  }
}
