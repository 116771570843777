import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyCQQrYt7gOBvdEaMVbS0SVyUX8gb67aGv8",
  authDomain: "cashilia.firebaseapp.com",
  projectId: "cashilia",
  storageBucket: "cashilia.appspot.com",
  messagingSenderId: "920429940760",
  appId: "1:920429940760:web:914109284a86df6f4d736d",
  measurementId: "G-C158C5TD1P",
  databaseURL: "https://main_01.europe-west1.firebasedatabase.app",
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const storage = getStorage(app);
