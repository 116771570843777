import React from "react";

export default class MenuPage extends React.Component<any, any> {
  constructor(props: {} | Readonly<{}>) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <section className="menu">
          <a href="/" className="image-container">
            <img
              src="../assets/img/logo_main.png"
              alt=""
              className="image-container mb-2"
            />
          </a>
          <br />
          <h4 className="text-center mt-2">
            <button type="button" className="btn btn-outline-warning m-1">
              <a href="/carta">Alla Carta</a>
            </button>{" "}
            <button type="button" className="btn btn-outline-warning m-1">
              <a href="/beverage">Beverage</a>
            </button>{" "}
            <button type="button" className="btn btn-outline-warning m-1">
              <a href="/pranzo">Pranzo</a>
            </button>{" "}
            <button type="button" className="btn btn-outline-warning m-1">
              <a href="cena">Cena</a>
            </button>
          </h4>
          <div className="transbox-menu">
            <div className="text-center fst-italic fs-6 fw-lighter p-5">
              <h3>
                "La proposta culinaria di cà shilia è frutto di una sapiente
                unione di ingredienti, tradizioni e culture apparentemente molto
                lontane tra di loro, che in una visione innovativa si sposano
                perfettamente. Le protagoniste di questo connubio sono la cucina
                giapponese e quella dell’Italia meridionale; l’amore che le lega
                è quello per il pesce, ingrediente principale dei piatti di
                entrambi i mondi, che cà shilia vuole farvi assaporare. Le
                tecniche della preparazione tradizionale giapponese del sushi
                sono arricchite dall’uso degli ingredienti tipici del sud
                Italia, più in generale del mediterraneo, e in particolare
                quelli regionali calabresi e siciliani. Mangiare giapponese è
                un’esperienza culturale che permette di assaporare la concezione
                filosofica buddista dalla quale questa cucina trae i principi
                fondamentali; mangiare SUSHI MEDITERRANEO significa
                intensificare al massimo livello questa esperienza, immergendosi
                altresì in una cultura millenaria che affonda le proprie radici
                nel mondo greco e che pervade Sicilia e Calabria, entrambe
                intrise di storia, miti e leggende che cà shilia vi racconterà
                cullandovi tra le sue onde. Non vi resta che affacciarvi alla
                sua finestra sul mare e iniziare il viaggio!"{" "}
              </h3>
            </div>
          </div>
          <h4 className="text-center mt-2">
            <button type="button" className="btn btn-outline-warning m-1">
              <a href="/carta">Alla Carta</a>
            </button>{" "}
            <button type="button" className="btn btn-outline-warning m-1">
              <a href="/beverage">Beverage</a>
            </button>{" "}
            <button type="button" className="btn btn-outline-warning m-1">
              <a href="/pranzo">Pranzo</a>
            </button>{" "}
            <button type="button" className="btn btn-outline-warning m-1">
              <a href="cena">Cena</a>
            </button>
          </h4>
        </section>
      </>
    );
  }
}
