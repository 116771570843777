import React from "react";
import {
  addDoc,
  collection,
  setDoc,
  deleteDoc,
  doc,
  query,
  onSnapshot,
  getDoc,
  getDocs,
} from "firebase/firestore";
import Form_001 from "./dashboard-forms/form001";
//import { initializeApp } from "firebase/app";
//import { DocumentData,QueryDocumentSnapshot,getFirestore,} from "@firebase/firestore";
import PictureUpload from "./dashboard-forms/picture-upload";
///import { getStorage } from "firebase/storage";
import MenuSettings from "./dashboard-forms/dashboard-menu";
import "firebase/compat/firestore";
import { db } from "./firebase-config";
// Import the functions you need from the SDKs you need
//import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
/*const firebaseConfig = {
  apiKey: "AIzaSyCQQrYt7gOBvdEaMVbS0SVyUX8gb67aGv8",
  authDomain: "cashilia.firebaseapp.com",
  projectId: "cashilia",
  storageBucket: "cashilia.appspot.com",
  messagingSenderId: "920429940760",
  appId: "1:920429940760:web:914109284a86df6f4d736d",
  measurementId: "G-C158C5TD1P",
  databaseURL: "https://main_01.europe-west1.firebasedatabase.app",
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const storage = getStorage(app);
*/
//const analytics = getAnalytics(app);

interface Form_01 {
  lang: Array<string>;
  dinnerTime: string;
  logoLink: string;
  lunchTime: string;
  phoneNumber: string;
}

interface MenuInterface {
  menus: Array<{
    id: string;
    name: string;
    contents: Array<{ type: string; content: string }>;
  }>;
}

interface MenuInterfaceItem {
  name: string;
  contents: Array<{ type: string; content: string }>;
}

export default class Dashboard extends React.Component<any, any> {
  state = { menu: [] };
  constructor(props: {} | Readonly<{}>) {
    super(props);
    this.handleChangePassword = this.handleChangePassword.bind(this);
    this.handleChangeUser = this.handleChangeUser.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    this.renderPosts();
    //console.log(this.state);
  }

  componentDidUpdate(
    prevProps: Readonly<any>,
    prevState: Readonly<any>,
    snapshot?: any
  ): void {
    console.log(this.state.menu);
    if (this.state.menu.length == 0) {
      this.renderPosts();
      //console.log(this.state);
    }
  }

  renderPosts = async () => {
    try {
      const colRef = collection(db, "menu");
      await getDocs(colRef).then((menu: any) => {
        const projects: any[] = [];
        menu.docs.map((doc: { id: any; data: () => any }) => {
          //console.log(doc.id);
          const menU = { ...doc.data(), id: doc.id };
          projects.push(menU);
        });
        console.log(projects);
        if (projects.length >= 1) {
          this.setState({
            menu: projects,
          });
        }
      });

      // this will re render the view with new data
    } catch (err) {
      console.log(err);
    }
  };

  handleChangePassword(this: any, event: { target: { value: any } }) {
    this.setState({ pass: event.target.value });
  }

  handleChangeUser(this: any, event: { target: { value: any } }) {
    this.setState({ user: event.target.value });
  }

  handleSubmit(this: any, event: { preventDefault: () => void }) {
    event.preventDefault();
  }

  onSubmitData(state: Form_01) {
    console.log(state);
    const data = state;

    try {
      //addDoc(collection(db, "todos"), {todo: data,});
      setDoc(doc(db, "todos", "VOXmLV8Si77HcOpJprIB"), data);
      console.log("Document written with ID: ");
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  }

  onSubmitMenu(state: MenuInterface, idMenu: string) {
    const data = state.menus.find(
      (menu: {
        name: string;
        contents: Array<{ type: string; content: string }>;
        id: string;
      }) => idMenu === menu.id
    );
    let id = idMenu.toString();

    try {
      setDoc(doc(db, "menu", id), data);
      console.log("Document written with ID: ");
      alert("Salvato!!!");
    } catch (e) {
      console.error("Error adding document: ", e);
      alert("ERRORE, prova piu' tardi o chiedi assistenza!");
    }
  }

  deleteMenu(indexMenu: string) {
    let ID = indexMenu.toString();
    try {
      deleteDoc(doc(db, "menu", ID)).then((resp) => {
        console.log(resp);
        window.location.reload();
      });
      // only splice array when item is found
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  }

  newMenu(state: MenuInterfaceItem) {
    try {
      addDoc(collection(db, "menu"), state);
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  }

  render() {
    return (
      <div className="mt-1">
        {/*Main Navbar*/}
        <nav className="navbar bg-dark navbar-expand-lg">
          <div className="container-fluid">
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                <li>
                  <a
                    href="/"
                    className="d-flex align-items-center mb-3 mb-md-0 me-md-auto  text-decoration-none"
                  >
                    <svg className="bi me-2" width="40" height="32">
                      <use href="#bootstrap"></use>
                    </svg>
                    <span className="fs-4">Ca' Shilia</span>
                  </a>
                </li>
              </ul>
              <div className="d-flex">
                <i className="bi bi-person-circle"></i>
              </div>
            </div>
          </div>
        </nav>

        <div className="wrapper row">
          {/*Side bar*/}
          <nav
            className="col-md-2"
            style={{ backgroundColor: "#0c0b09", height: "100vh" }}
          >
            <div>
              <br />
              <ul className="nav nav-pills flex-column mb-auto">
                <li className="nav-item">
                  <a href="#" className="nav-link">
                    <svg className="bi me-2" width="16" height="16">
                      <use href="#home"></use>
                    </svg>
                    Home
                  </a>
                </li>
                <li>
                  <a href="#" className="nav-link ">
                    <svg className="bi me-2" width="16" height="16">
                      <use href="#menu"></use>
                    </svg>
                    Menu Edit
                  </a>
                </li>
              </ul>
            </div>
          </nav>
          <main className="col-md-8">
            <section>
              <Form_001 onSubmitData={this.onSubmitData}></Form_001>
              <hr />
              <PictureUpload></PictureUpload>
              <hr />
              <MenuSettings
                onSubmitMenu={this.onSubmitMenu}
                deleteMenu={this.deleteMenu}
                newMenu={this.newMenu}
                stateP={this.state.menu}
              ></MenuSettings>
            </section>
          </main>
        </div>
      </div>
    );
  }
}
