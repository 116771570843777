import React from "react";

export default class LunchMenuLayer extends React.Component<any, any> {
  constructor(props: {} | Readonly<{}>) {
    super(props);
  }

  render() {
    return (
      <section className="menu-lunch">
        <a href="/" className="image-container">
          <img
            src="../assets/img/logo_main.png"
            alt=""
            className="image-container mb-2"
          />
        </a>
        <br />
        <h4 className="text-center mt-2">
          <button type="button" className="btn btn-outline-warning m-1">
            <a href="/menu"> Menu Principale</a>
          </button>
        </h4>
        <br />

        <div className="card-group m-5">
          <div className="card m-2 mb-3">
            <a href="/pranzoCarta">
              <img
                src="../assets/img/CM5A3304_.jpg"
                className="card-img-top"
                alt="..."
              />
            </a>
            <div className="card-body">
              <h5 className="card-title">Menù Open Sushi Pranzo</h5>
              <p className="card-text"></p>
            </div>
          </div>
          <div className="card m-2 mb-3">
            <a href="/pranzoPanini">
              <img
                src="../assets/img/panini_.jpg"
                className="card-img-top"
                alt="..."
              />
            </a>
            <div className="card-body">
              <h5 className="card-title">Menù Panini</h5>
              <p className="card-text"></p>
            </div>
          </div>
          <div className="card m-2 mb-3">
            <a href="/pranzoPoke">
              <img
                src="../assets/img/poke_.jpg"
                className="card-img-top"
                alt="..."
              />
            </a>
            <div className="card-body">
              <h5 className="card-title">Menù Pokè</h5>
              <p className="card-text"></p>
            </div>
          </div>
        </div>

        <br />
        <h4 className="text-center mt-2">
          <br />
          <br />
          <br />

          <button type="button" className="btn btn-outline-warning m-1">
            <a href="/menu"> Menu Principale</a>
          </button>
        </h4>
      </section>
    );
  }
}
